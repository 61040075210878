import { shadows } from '@f8n/tokens';
import { styled, darkMode } from '@f8n-frontend/stitches';
import * as SliderPrimitive from '@radix-ui/react-slider';

const THUMB_SIZE = 20;
const TRACK_HEIGHT = 8;

const Track = styled(SliderPrimitive.Track, {
  backgroundColor: '$black10',
  position: 'relative',
  flexGrow: 1,
  height: TRACK_HEIGHT,
  borderRadius: '$round',
  transition: 'background $1 $ease',

  [darkMode]: {
    backgroundColor: '$black20',
  },
});

const Range = styled(SliderPrimitive.Range, {
  position: 'absolute',
  backgroundColor: '$black100',
  borderRadius: '9999px 0 0 9999px',
  height: '100%',
});

const Thumb = styled(SliderPrimitive.Thumb, {
  display: 'block',
  width: THUMB_SIZE,
  height: THUMB_SIZE,
  borderRadius: '50%',
  boxShadow: '$regular0',
  backgroundColor: '$white100',
  border: '1px solid transparent',
  willChange: 'transform',
  transition:
    'border $1 $ease, box-shadow $1 $ease, outline $1 $ease, transform $1 $ease',

  '&:active': {
    borderColor: 'transparent',
    boxShadow: '$soft0',
  },

  '&:focus-visible': {
    borderColor: '$black100',
    outline: '4px solid $black30',
  },

  '@hover': {
    '&:hover': {
      boxShadow: '$regular1',
      transform: 'scale(1.1)',
    },
  },

  [darkMode]: {
    boxShadow: shadows.regular2,
    backgroundColor: '$black100',
    '&:active': {
      boxShadow: shadows.regular2,
    },
  },
});

const Root = styled(SliderPrimitive.Root, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  touchAction: 'none',
  width: '100%',
  height: THUMB_SIZE,

  '@hover': {
    '&:hover': {
      [`${Track}`]: {
        backgroundColor: '$black15',
      },
    },
  },

  [darkMode]: {
    '@hover': {
      '&:hover': {
        [`${Track}`]: {
          backgroundColor: '$black30',
        },
      },
    },
  },
});

function SliderBase(props: SliderPrimitive.SliderProps) {
  return (
    <Root {...props}>
      <Track>
        <Range />
      </Track>
      <Thumb />
    </Root>
  );
}

const Slider = Object.assign(SliderBase, {
  THUMB_SIZE,
  TRACK_HEIGHT,
});

export default Slider;
