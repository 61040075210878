import Box from 'components/base/Box';
import Mono from 'components/base/Mono';

import { BalancedMedia } from 'hooks/use-balanced-media';
import { formatNumber } from 'utils/formatters';
import { MAX_BALANCING_SIZE_REDUCTION } from 'utils/media-balancing';

type DebugBalancedMediaProps = Pick<
  BalancedMedia,
  'mediaCoverage' | 'mediaScaleFactor' | 'mediaTransformScale'
>;

export default function DebugBalancedMedia(props: DebugBalancedMediaProps) {
  return (
    <Box
      css={{
        position: 'absolute',
        top: '-20px',
        display: 'flex',
        gap: '$4',
        background: '$black100',
        color: '$white100',
        padding: '$1',
        borderRadius: '$1',
        opacity: 1,
        fontSize: 10,
      }}
    >
      {/* What percentage of the container does the asset cover? */}
      <Mono>
        Covers: <b>{formatNumber(props.mediaCoverage)}</b>
      </Mono>
      {/* % through scaling range */}
      <Mono>
        Factor:{' '}
        <b>
          {formatNumber(props.mediaScaleFactor / MAX_BALANCING_SIZE_REDUCTION)}
        </b>
      </Mono>
      {/* % to scale by */}
      <Mono>
        Scale: <b>{formatNumber(props.mediaTransformScale)}</b>
      </Mono>
    </Box>
  );
}
