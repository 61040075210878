import { useEffect, useRef, useState } from 'react';

import { calculateAssetCoverage, getAspectRatioDecimal } from 'utils/media';
import { calculateCoverageScalingFactor } from 'utils/media-balancing';

import { AssetSummary, Dimensions } from 'types/media';

export type BalancedMedia = {
  /**
   * decimal representation of the container's aspect ratio
   */
  containerAspectDecimal: number;
  /**
   * The dimensions of the container
   */
  containerDimensions: Dimensions;
  /**
   * % from 0...1 indicating how much of the container the media is filling.
   * 1 means the media fills 100% of it's container
   * */
  mediaCoverage: number;
  /**
   * % from 0...1 indicating how much we should downscale the media to better balance it within the container.
   * 1 means downscale as much as possible
   * 0 means don't downscale at all
   * */
  mediaScaleFactor: number;
  /**
   * % from 0...1 representing a CSS transform.
   * 1 means downscale render at normal size
   * 0.9 means don't downscale by 10%
   * */
  mediaTransformScale: number;
};

/**
 * @returns containerRef + assetCoverage (betweeen 0 and 1)
 */
export default function useBalancedMedia(options: {
  asset: AssetSummary | null;
  containerRef: React.RefObject<HTMLDivElement>;
  enabled: boolean;
}): BalancedMedia {
  const { asset, enabled = true, containerRef } = options;

  const containerDimensionsRef = useRef<Dimensions>({
    width: 0,
    height: 0,
  });
  const [coverage, setCoverage] = useState(1);
  const [containerAspectDecimal, setContainerAspectDecimal] =
    useState<number>(0);

  useEffect(() => {
    const el = containerRef.current;

    if (!el) return;

    const containerRect = el.getBoundingClientRect();
    const container: Dimensions = {
      width: containerRect.width,
      height: containerRect.height,
    };

    containerDimensionsRef.current = container;
    setContainerAspectDecimal(getAspectRatioDecimal(container));

    if (!asset) return;

    const assetCoverage = calculateAssetCoverage({
      asset,
      container,
    });

    if (!asset) return;

    setCoverage(assetCoverage);
  }, [asset]);

  const mediaCoverage = coverage;
  const mediaScaleFactor = calculateCoverageScalingFactor(mediaCoverage);
  const mediaTransformScale = enabled ? 1 - mediaScaleFactor : 1;

  const containerDimensions = containerDimensionsRef.current;

  return {
    containerAspectDecimal,
    containerDimensions,
    mediaCoverage,
    mediaScaleFactor,
    mediaTransformScale,
  };
}
