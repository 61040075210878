import {
  PlayIcon,
  ExitFullscreenIcon,
  FullscreenIcon,
  MutedIcon,
  PauseIcon,
  UnmutedIcon,
} from '@f8n/icons';
import { darkTheme, styled } from '@f8n-frontend/stitches';
import type { HlsConfig } from 'hls.js';
import { useState, useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import { useFullscreen, useToggle } from 'react-use';
import { match } from 'ts-pattern';

import Slider from 'components/Slider';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import Tooltip from 'components/base/Tooltip';

import { isNumberType } from 'utils/helpers';

import { VideoMediaAsset } from 'types/media';

import Badge from './base/Badge';

type VideoPlayerProps = Pick<VideoMediaAsset, 'src' | 'poster' | 'controls'>;

type VideoPlayerState = {
  loaded: number;
  muted: boolean;
  playing: boolean;
  ready: boolean;
  seeking: boolean;
  hasAudio: boolean;
};

export default function VideoPlayer(props: VideoPlayerProps) {
  const { controls, src, poster } = props;

  const [playerState, setPlayerState] = useState<VideoPlayerState>({
    loaded: 0,
    muted: true,
    playing: true,
    ready: false,
    seeking: false,
    hasAudio: false,
  });

  const { muted, loaded, playing, ready, seeking, hasAudio } = playerState;

  const hasFullscreenSupport = true;
  const [hasHovered, setHasHovered] = useState(false);

  const playerRef = useRef<ReactPlayer>(null);

  const ref = useRef(null);
  const [isToggled, toggleFullscreen] = useToggle(false);
  const isFullscreen = useFullscreen(ref, isToggled, {
    onClose: () => toggleFullscreen(false),
  });

  const onFullscreenClick = toggleFullscreen;

  const handleReady = (player: ReactPlayer) => {
    const hlsPlayer = player.getInternalPlayer('hls') as HlsConfig;
    const videoPlayer = player.getInternalPlayer() as HTMLVideoElement;

    const hasAudio = match({ hlsPlayer, videoPlayer })
      .when(
        ({ hlsPlayer }) => {
          return Boolean(hlsPlayer?.audioTrackController);
        },
        () => true
      )
      .when(
        ({ videoPlayer }) => {
          return (
            // @ts-expect-error - non-standard property
            Boolean(videoPlayer.mozHasAudio) ||
            // @ts-expect-error - non-standard property
            Boolean(videoPlayer.webkitAudioDecodedByteCount) ||
            // @ts-expect-error - non-standard property
            Boolean(videoPlayer.audioTracks?.length)
          );
        },
        () => true
      )
      .otherwise(() => false);

    setPlayerState((state) => ({
      ...state,
      hasAudio,
      ready: true,
    }));
  };

  const getCurrentProgress = () => {
    if (!playerRef.current) return 0;
    return playerRef.current.getCurrentTime() / playerRef.current.getDuration();
  };

  const getControls = () => {
    if (controls === 'none' || !ready) {
      return null;
    }

    const shouldShowMutedBadge = hasAudio && muted && !hasHovered;

    if (controls === 'audio-only' && (hasFullscreenSupport || hasAudio)) {
      return (
        <>
          <BadgeContainer>
            {shouldShowMutedBadge && <MutedBadge />}
          </BadgeContainer>
          <Controls className={darkTheme} css={{ justifyContent: 'flex-end' }}>
            {hasAudio && (
              <AudioControl
                muted={muted}
                onToggle={() => {
                  setPlayerState((state) => ({
                    ...state,
                    muted: !state.muted,
                  }));
                }}
              />
            )}
            {hasFullscreenSupport && (
              <FullScreenControl
                isFullscreen={isFullscreen}
                onFullscreenClick={onFullscreenClick}
              />
            )}
          </Controls>
        </>
      );
    }

    if (controls === 'all' && (hasFullscreenSupport || hasAudio)) {
      return (
        <>
          <BadgeContainer>
            {shouldShowMutedBadge && <MutedBadge />}
          </BadgeContainer>
          <Controls className={darkTheme}>
            <PlayControl
              playing={playing}
              onToggle={() => {
                setPlayerState((state) => ({
                  ...state,
                  playing: !state.playing,
                }));
              }}
            />
            <SeekControl
              loaded={loaded}
              currentProgress={getCurrentProgress()}
              onProgressChange={(value) => {
                if (playerRef && playerRef.current && isNumberType(value[0])) {
                  playerRef.current.seekTo(value[0]);
                }
              }}
              onMouseDown={() => {
                setPlayerState((state) => ({
                  ...state,
                  seeking: true,
                }));
              }}
              onMouseUp={() => {
                setPlayerState((state) => ({
                  ...state,
                  seeking: false,
                }));
              }}
            />
            {hasAudio && (
              <AudioControl
                muted={muted}
                onToggle={() => {
                  setPlayerState((state) => ({
                    ...state,
                    muted: !state.muted,
                  }));
                }}
              />
            )}
            {hasFullscreenSupport && (
              <FullScreenControl
                isFullscreen={isFullscreen}
                onFullscreenClick={onFullscreenClick}
              />
            )}
          </Controls>
        </>
      );
    }

    return null;
  };

  return (
    <Container ref={ref} onMouseOver={() => setHasHovered(true)}>
      {/* This additional image prevents the stream from collapsing in size */}
      <img
        alt=""
        aria-hidden="true"
        src={poster}
        style={{ visibility: 'hidden' }}
      />
      <ReactPlayer
        loop
        playsinline
        muted={muted}
        playing={playing}
        progressInterval={100}
        ref={playerRef}
        url={src}
        width="100%"
        height="100%"
        onReady={handleReady}
        onProgress={(progress) => {
          if (!seeking) {
            setPlayerState((state) => ({
              ...state,
              loaded: progress.loaded,
            }));
          }
        }}
        config={{
          file: {
            attributes: {
              poster,
            },
            hlsOptions: {
              // capLevelToPlayerSize: false,
              startLevel: 4, // number between 0...6
            },
          },
        }}
      />
      {getControls()}
    </Container>
  );
}

type PlayControlProps = {
  playing: boolean;
  onToggle(): void;
};

function PlayControl(props: PlayControlProps) {
  return (
    <Tooltip content={props.playing ? 'Pause' : 'Play'}>
      <PlayerButton onClick={props.onToggle}>
        {props.playing ? <PauseIcon /> : <PlayIcon />}
      </PlayerButton>
    </Tooltip>
  );
}

type SeekControlProps = {
  loaded: number;
  currentProgress: number;
  onProgressChange(progress: number[]): void;
  onMouseDown(): void;
  onMouseUp(): void;
};

function SeekControl(props: SeekControlProps) {
  return (
    <ProgressContainer>
      <FullProgressRange />
      <Box style={{ width: `${props.loaded * 100}%` }}>
        <Slider
          value={[props.currentProgress]}
          max={1}
          step={0.01}
          onValueChange={props.onProgressChange}
          onMouseDown={props.onMouseDown}
          onMouseUp={props.onMouseUp}
        />
      </Box>
    </ProgressContainer>
  );
}

type AudioControlProps = {
  muted: boolean;
  onToggle(): void;
};

function AudioControl(props: AudioControlProps) {
  return (
    <Tooltip content={props.muted ? 'Unmute' : 'Mute'}>
      <PlayerButton onClick={props.onToggle}>
        {props.muted ? <MutedIcon /> : <UnmutedIcon />}
      </PlayerButton>
    </Tooltip>
  );
}

const VideoPlayerBadge = styled(Badge, {
  background: '$black90',
  color: '$white100',
  zIndex: 2,
});

function MutedBadge() {
  return (
    <VideoPlayerBadge size={1}>
      <span>Audio muted</span>
      <MutedIcon />
    </VideoPlayerBadge>
  );
}

type FullScreenControlProps = {
  isFullscreen: boolean | undefined;
  onFullscreenClick: (() => void) | undefined;
};

function FullScreenControl(props: FullScreenControlProps) {
  if (!props.onFullscreenClick) return null;

  return (
    <Tooltip
      content={props.isFullscreen ? 'Exit full screen' : 'Enter full screen'}
    >
      <PlayerButton onClick={props.onFullscreenClick}>
        {props.isFullscreen ? <ExitFullscreenIcon /> : <FullscreenIcon />}
      </PlayerButton>
    </Tooltip>
  );
}

const ControlsContainer = styled('div', {
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  gap: '$3',
  display: 'flex',
  alignItems: 'center',
  transition: 'all $2 $ease',
});

const BadgeContainer = styled(ControlsContainer, {
  justifyContent: 'flex-end',
  padding: '$4',
  display: 'none',
  top: 0,
  bottom: 'auto',

  '@hover': {
    top: 'auto',
    bottom: 0,
    display: 'flex',
  },
});

const Controls = styled(ControlsContainer, {
  background: 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65))',
  paddingTop: '$6',
  paddingBottom: '$4',
  paddingX: '$4',
  justifyContent: 'space-between',

  opacity: 1,
  visibility: 'visible',

  '@hover': {
    opacity: 0,
    visibility: 'hidden',
  },
});

const Container = styled('div', {
  position: 'relative',
  width: '100%',

  img: {
    display: 'block',
  },

  video: {
    display: 'block',
    objectFit: 'contain',
    maxHeight: 'calc(100vh - 40px)',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
  },

  '@hover': {
    '&:hover': {
      [`${Controls}`]: {
        opacity: 1,
        visibility: 'visible',
      },
      [`${BadgeContainer}`]: {
        opacity: 0,
        visibility: 'hidden',
      },
    },
  },
});

const ProgressContainer = styled('div', {
  flex: 1,
  position: 'relative',
  input: {
    width: '100%',
  },
});

const FullProgressRange = styled('div', {
  height: Slider.TRACK_HEIGHT,
  width: '100%',
  position: 'absolute',
  borderRadius: '$round',
  top: '50%',
  backgroundColor: '$black20',
  transform: 'translateY(-50%)',
});

const PlayerButton = styled(Button, {
  '&:active, &[data-state=open]': {
    // To disable the tooltip adding a transform
    transform: 'none !important',
  },
});

PlayerButton.defaultProps = {
  size: 0,
  variant: 'ghost',
  icon: 'standalone',
};
