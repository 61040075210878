import { toPx } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import { useRef } from 'react';
import { match, P } from 'ts-pattern';

import VideoPlayer from 'components/VideoPlayer';

import useBalancedMedia from 'hooks/use-balanced-media';
import useHasIntersected from 'hooks/use-has-intersected';
import useLoadedPreviewMedia from 'hooks/use-loaded-preview-media';
import { VIDEO_ASSET_QUALITY_VARIANTS } from 'lib/assets';
import { DEFAULT_VIDEO_PROPS } from 'lib/constants';
import { lerp, lerpThroughArray, repeat } from 'utils/helpers';
import { mapImgixGifToMp4, optimizeAsset } from 'utils/imgix';
import { getAspectRatioComparisonDescriptor } from 'utils/media-balancing';
import { createClassSelector } from 'utils/styles';

import {
  LaunchMediaAsset,
  OptimizeMediaAssetOptions,
  VideoMediaAsset,
} from 'types/media';

import Media from './Media';
import DebugBalancedMedia from './debug/DebugBalancedMedia';

// Toggle this on in development to debug the scaling factor.
const DEBUG = false;

const MEDIA_STACK_CLASSNAME = 'media-stack';
export const MEDIA_STACK_SELECTOR = createClassSelector(MEDIA_STACK_CLASSNAME);

type StackCount = 4 | 3 | 2 | 1 | 0;

type MediaStackProps = {
  media: LaunchMediaAsset;
  color?: string;
  /**
   * Number of stacked items below the top media item
   * 2 = 1 main image + 2 below
   */
  stackCount?: StackCount;
  /**
   * Used to make the spacing around the stack equal when displayed in a grid
   * e.g. when a stack with a count of 1 is displayed adjacent to a stack with a count of 3
   * pass alignWithStackCount={3} to the stack with a count of 1 to make them horizontally aligned.
   */
  alignWithStackCount?: StackCount;
  hasBalancedMedia?: boolean;
};

const MIN_OFFSET = 4;
const MAX_OFFSET = 18;

const WIDTH_FACTOR_MAX = 1000;

export default function MediaStack(props: MediaStackProps) {
  const {
    color = '$white100',
    media,
    stackCount = 3,
    alignWithStackCount,
    hasBalancedMedia = true,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const hasIntersected = useHasIntersected(containerRef, { threshold: 0.3 });
  const loadedMedia = useLoadedPreviewMedia(media, { enabled: hasIntersected });
  const balancer = useBalancedMedia({
    asset: loadedMedia,
    containerRef,
    enabled: hasBalancedMedia,
  });
  const backgroundColor = loadedMedia ? color : undefined;

  const stackCountToAlignWith = alignWithStackCount
    ? alignWithStackCount
    : stackCount;

  const mediaShape =
    hasBalancedMedia && loadedMedia
      ? getAspectRatioComparisonDescriptor({
          assetAspectDecimal: loadedMedia.aspectRatioDecimal,
          containerAspectDecimal: balancer.containerAspectDecimal,
        })
      : 'equal';

  const getOffsetDistance = () => {
    /**
     * Calculate the width in px of the image when rendered within a container. This will be the smallest of:
     *
     * 1. The width of the container (used when the image is larger than it's container)
     * 2. The real width of the image (used when the image is smaller than it's container)
     * 3. A fallback WIDTH_FACTOR_MAX (used large images). This prevents the stack growing too large for large images.
     */
    const widthNumerator = Math.min(
      balancer.containerDimensions.width,
      loadedMedia?.width ?? WIDTH_FACTOR_MAX,
      WIDTH_FACTOR_MAX
    );
    const widthDenominator = WIDTH_FACTOR_MAX;
    const offsetWeightRatio = widthNumerator / widthDenominator;

    return lerp(MIN_OFFSET, MAX_OFFSET, offsetWeightRatio);
  };

  const layerOffsetDistance = getOffsetDistance();
  const totalOffsetDistance = layerOffsetDistance * stackCountToAlignWith;

  const getRootTransform = () => {
    const balancerScale = balancer.mediaTransformScale;

    return `scale(${balancerScale}) translateY(${-totalOffsetDistance / 2}px)`;
  };

  const getContainerPadding = () => {
    return {
      '--stack-max-height': `calc(100% - ${toPx(totalOffsetDistance)})`,
      '--stack-max-width': `calc(100% - ${toPx(totalOffsetDistance)})`,
    } as React.CSSProperties;
  };

  return (
    <MediaStackContainer
      ref={containerRef}
      debug={DEBUG}
      isMediaLoaded={loadedMedia !== null}
      mediaShape={mediaShape}
      stackCount={stackCount}
      className={MEDIA_STACK_CLASSNAME}
      style={{
        ...getContainerPadding(),
      }}
    >
      {DEBUG && hasBalancedMedia && <DebugBalancedMedia {...balancer} />}
      <Media.Root
        css={{
          backgroundColor,
        }}
        style={{
          // This is a fix for Firefox & Safari because of a bug with flexbox
          // not wrapping around children. TODO: Explore using CSS grid
          aspectRatio: loadedMedia ? loadedMedia.aspectRatio : undefined,
          transform: getRootTransform(),
        }}
      >
        {repeat(stackCount, '').map((_, index, array) => {
          const stackItemCount = array.length;
          const stackPosition = stackItemCount - index;

          const itemStagger = TRANSITION_STAGGER_MS * stackPosition;
          const initialStaggerDelay = 150;
          const stagger = initialStaggerDelay + itemStagger;
          const transitionDelay = `${stagger}ms`;

          const xOffset = layerOffsetDistance * stackPosition;
          const yOffset = 0 - xOffset;

          return (
            <StackLayer
              key={index}
              style={
                {
                  transitionDelay,
                  '--stack-shadow-weight': lerpThroughArray({
                    min: 0.5,
                    max: 0.8,
                    index,
                    array,
                  }),
                  left: toPx(xOffset),
                  right: toPx(xOffset),
                  bottom: toPx(yOffset),
                } as React.CSSProperties
              }
            >
              {media.type === 'video' ? (
                <BaseImage
                  src={media.src}
                  as="video"
                  poster={media.poster}
                  {...DEFAULT_VIDEO_PROPS}
                />
              ) : (
                <BaseImage alt={media.alt} src={media.src} />
              )}
            </StackLayer>
          );
        })}
        <TopLayer>
          {media.type === 'video' ? (
            <>
              {media.controls === 'audio-only' || media.controls === 'all' ? (
                <VideoPlayer
                  controls={media.controls}
                  src={media.src}
                  poster={media.poster}
                />
              ) : (
                <MainImage
                  css={{ backgroundColor }}
                  src={media.src}
                  poster={media.poster}
                  as="video"
                  {...DEFAULT_VIDEO_PROPS}
                />
              )}
            </>
          ) : (
            <MainImage
              css={{ backgroundColor }}
              alt={media.alt}
              src={media.src}
            />
          )}
        </TopLayer>
      </Media.Root>
    </MediaStackContainer>
  );
}

const BaseImage = styled(Media, {
  display: 'block',
  borderRadius: '$1',
  transition: 'opacity $3 $ease, transform 0.6s $ease',
});

const MainImage = styled(BaseImage, {
  transitionDuration: '$transitions$2',
});

const TopLayer = styled(Media.Tint, {
  width: '100%',
  overflow: 'hidden',
  transition: 'opacity $3 $ease, transform 0.6s $ease',
  willChange: 'opacity, transform',
  boxShadow: '$regular0',
});

const StackLayer = styled(Media.Tint, {
  position: 'absolute',
  transition: 'opacity $3 $ease, transform 0.6s $ease',
  borderRadius: 'inherit',

  '&:before': {
    content: '',
    position: 'absolute',
    inset: 0,
    borderRadius: 'inherit',
    boxShadow: '$regular0',
    opacity: 'var(--stack-shadow-weight, 0.8)',
  },
});

const TRANSITION_STAGGER_MS = 100;

/**
 * CSS to allow the media to grow to the max height of the container while preserving its aspect ratio.
 */
const growToMaxHeight = {
  // Prevent the Root from overflowing it's parent.
  // Note that --stack-max-height is used to account for the height of the stack offset
  [`${Media.Root}`]: {
    maxHeight: 'var(--stack-max-height, 100%)',
  },

  // Allow the media to grow to the max height of the container
  // Note that --stack-max-height is used to account for the height of the stack offset
  [`${Media}`]: {
    height: 'var(--stack-max-height, 100%)',
  },
};

/**
 * CSS to allow the media to grow to the max width of the container while preserving its aspect ratio.
 * Note: This assumes that the container has a fixed aspect ratio.
 */
const growToMaxWidth = {
  // Note that --stack-max-width is used to account for the height of the stack offset
  [`${Media.Root}`]: {
    maxWidth: 'var(--stack-max-width, 100%)',
  },

  [`${Media}`]: {
    height: 'auto',
  },
};

const MediaStackContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.6s $ease',
  position: 'relative',

  [`${Media.Root}`]: {
    borderRadius: '$1',
    transition: 'opacity $3 $ease, transform 0.6s $ease',
    willChange: 'opacity, transform',
  },

  variants: {
    debug: {
      true: {
        outline: '1px solid $black20',
      },
      false: {},
    },
    mediaShape: {
      wider: growToMaxWidth,
      taller: growToMaxHeight,
      equal: growToMaxHeight,
    },
    stackCount: {
      4: {
        [`${Media.Tint}`]: {
          '&:nth-child(1)': {
            [`${Media}`]: {
              opacity: 0.2,
            },
          },
          '&:nth-child(2)': {
            [`${Media}`]: {
              opacity: 0.4,
            },
          },
          '&:nth-child(3)': {
            [`${Media}`]: {
              opacity: 0.6,
            },
          },
          '&:nth-child(4)': {
            [`${Media}`]: {
              opacity: 0.8,
            },
          },
        },
      },
      3: {
        [`${Media.Tint}`]: {
          '&:nth-child(1)': {
            [`${Media}`]: {
              opacity: 0.4,
            },
          },
          '&:nth-child(2)': {
            [`${Media}`]: {
              opacity: 0.6,
            },
          },
          '&:nth-child(3)': {
            [`${Media}`]: {
              opacity: 0.8,
            },
          },
        },
      },
      2: {
        [`${Media.Tint}`]: {
          '&:nth-child(1)': {
            [`${Media}`]: {
              opacity: 0.6,
            },
          },
          '&:nth-child(2)': {
            [`${Media}`]: {
              opacity: 0.8,
            },
          },
        },
      },
      1: {
        [`${Media.Tint}`]: {
          '&:nth-child(1)': {
            [`${Media}`]: {
              opacity: 0.8,
            },
          },
        },
      },
      0: {},
    },
    isMediaLoaded: {
      true: {
        [`${TopLayer}`]: {
          opacity: 1,
          transform: 'scale(1) translateY(0%)',
        },
        [`${Media.Tint}`]: {
          '&:nth-child(1)': {
            opacity: 1,
            transform: 'translateY(0)',
          },
          '&:nth-child(2)': {
            opacity: 1,
            transform: 'translateY(0)',
          },
          '&:nth-child(3)': {
            opacity: 1,
            transform: 'translateY(0)',
          },
          '&:nth-child(4)': {
            opacity: 1,
            transform: 'translateY(0)',
          },
        },
      },
      false: {
        [`${TopLayer}`]: {
          opacity: 0,
          transform: 'scale(0.98) translateY(2%)',
        },
        [`${Media.Tint}`]: {
          transform: 'scale(0.98) translateY(2%)',
          opacity: 0,
        },
      },
    },
  },
});

type MapArtworkToMediaAssetOptions = Partial<
  OptimizeMediaAssetOptions & {
    videoOptions: {
      quality: 'PREVIEW' | 'HIGH';
      controls?: VideoMediaAsset['controls'];
    };
  }
>;

/**
 * @deprecated because this function performs unsafe URL manipulation internally
 */
export const mapToLaunchMediaAsset = (
  asset: { assetUrl: string | null; mimeType: string | null },
  options: MapArtworkToMediaAssetOptions
): LaunchMediaAsset => {
  if (asset.assetUrl && asset.mimeType) {
    return mapLegacyAssetToLaunchMedia(
      asset as LegacyNonNullableAsset,
      options
    );
  } else {
    /**
     * This empty return is a way to avoid type errors when using this function.
     * When building a new version of this function, handle the case of null asset values outside of the function
     */
    return {
      alt: '',
      src: '',
      type: 'image',
    };
  }
};

/**
 * @deprecated because these values are nullable at the API layer
 */
type LegacyNonNullableAsset = { assetUrl: string; mimeType: string };

/**
 * @deprecated because this function performs unsafe URL manipulation internally
 */
const mapLegacyAssetToLaunchMedia = (
  asset: LegacyNonNullableAsset,
  options: MapArtworkToMediaAssetOptions
): LaunchMediaAsset =>
  match<LegacyNonNullableAsset, LaunchMediaAsset>(asset)
    .with(
      { mimeType: P.union('video/quicktime', 'video/mp4') },
      ({ assetUrl }) => {
        return {
          type: 'video',

          controls: options.videoOptions?.controls || 'none',

          // This src + poster manipulation is temporary unblock for MP4 editions.
          // TODO: replace when migrating to new media type from API
          src: new URL(
            VIDEO_ASSET_QUALITY_VARIANTS[
              options.videoOptions?.quality || 'PREVIEW'
            ],
            assetUrl
          ).toString(),
          poster: new URL(
            VIDEO_ASSET_QUALITY_VARIANTS.POSTER,
            assetUrl
          ).toString(),
        };
      }
    )
    .with({ mimeType: 'image/gif' }, ({ assetUrl }) => {
      return mapImgixGifToMp4(assetUrl, {
        ...options.imageOptions,
        fit: 'max',
      });
    })
    .otherwise(({ assetUrl }) => {
      const optimizedAsset = optimizeAsset(assetUrl, {
        ...options.imageOptions,
        fit: 'max',
        auto: 'format,compress',
      });
      return {
        type: 'image',
        src: optimizedAsset,
        alt: '',
      };
    });
