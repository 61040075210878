import { Ratio } from 'types/number';

import { lerp } from './helpers';
import { clampRatio } from './numbers';

const MIN_COVERAGE_FOR_BALANCING = 0.75; // 75% of the container
const MAX_COVERAGE_FOR_BALANCING = 1; // 100% of the container
const COVERAGE_RANGE = MAX_COVERAGE_FOR_BALANCING - MIN_COVERAGE_FOR_BALANCING;

export const MAX_BALANCING_SIZE_REDUCTION = 0.15; // reduce size by up to 15%
const MIN_BALANCING_SIZE_REDUCTION = 0;

export function calculateCoverageScalingFactor(coverage: number): number {
  // If coverage is outside of our range, always return zero
  if (
    coverage < MIN_COVERAGE_FOR_BALANCING ||
    coverage > MAX_COVERAGE_FOR_BALANCING
  ) {
    return 0;
  }

  const scalingRatio = mapCoverageToScalingRangeRatio(coverage);

  const scaleFactor = lerp(
    MIN_BALANCING_SIZE_REDUCTION,
    MAX_BALANCING_SIZE_REDUCTION,
    scalingRatio
  );

  return clampRatio(scaleFactor);
}

/**
 * Used to map the coverage value to a range between MIN_COVERAGE_FOR_BALANCING and MAX_COVERAGE_FOR_BALANCING
 *
 * @param coverage value between 0 and 1
 * @returns value between 0...1, where 0 = MIN_COVERAGE_FOR_BALANCING and 1 = MAX_COVERAGE_FOR_BALANCING
 */
function mapCoverageToScalingRangeRatio(coverage: number): Ratio {
  const ratio = (coverage - MIN_COVERAGE_FOR_BALANCING) / COVERAGE_RANGE;

  return clampRatio(ratio);
}

type AspectRatioDifferenceDescriptor = 'equal' | 'wider' | 'taller';

export function getAspectRatioComparisonDescriptor(options: {
  assetAspectDecimal: number;
  containerAspectDecimal: number;
}): AspectRatioDifferenceDescriptor {
  const { assetAspectDecimal, containerAspectDecimal } = options;

  const diff = assetAspectDecimal - containerAspectDecimal;

  /**
   * If the difference between the two aspect ratios is very small, we can safely consider them equal
   */
  if (Math.abs(diff) < 0.001) {
    return 'equal';
  }

  if (assetAspectDecimal > containerAspectDecimal) {
    return 'wider';
  }

  if (assetAspectDecimal < containerAspectDecimal) {
    return 'taller';
  }

  return 'equal';
}
